import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Vaga } from '../models/vaga';

@Injectable({
  providedIn: 'root'
})
export class VagaService {

  public readonly API = `${environment.BASE_URL}${environment.API}/vaga/`;

  constructor(
    private http: HttpClient
  ) { }

  getRegistro(id: number) {
    return this.http.post(`${this.API}registro`, {id: id});
  }

  getRegistros(params: HttpParams): any {
    return this.http.post(`${this.API}registros`, {params});
  }

  onApagar(id: number) {
    return this.http.post(`${this.API}apagar`, {id: id});
  }

  onSalvar(model: Vaga) {
    return this.http.post(`${this.API}salvar`, model);
  }

}
