import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { loadMessages, locale } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { Curriculo } from 'src/app/shared/models/curriculo';
import { CurriculoService } from 'src/app/shared/services/curriculo.service';

@Component({
  selector: 'app-curriculos',
  templateUrl: './curriculos.component.html',
  styleUrls: ['./curriculos.component.scss']
})
export class CurriculosComponent implements OnInit {

  id!: number;
  dataSource: any = {};
  linhaSelecionada!: Curriculo[];
  selectedRows: number[] = [];

  constructor(
    private route: Router,
    private serviceCurriculo: CurriculoService
  ) {
    loadMessages(ptMessages);
    locale(navigator.language);

    const isNotEmpty = (value: any) =>
      value !== undefined && value !== null && value !== '';

    this.dataSource = new DataSource({
      key: 'id',
      load(loadOptions: any) {
        let params: HttpParams = new HttpParams();
        [
          'filter',
          'group',
          'groupSummary',
          'parentIds',
          'requireGroupCount',
          'requireTotalCount',
          'searchExpr',
          'searchOperation',
          'searchValue',
          'select',
          'sort',
          'skip',
          'take',
          'totalSummary',
          'userData',
        ].forEach(function (i) {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            params = params.set(i, JSON.stringify(loadOptions[i]));
          }
        });
        return serviceCurriculo.getRegistros(params)
          .toPromise()
          .then((response: any) => ({
              data: response.data,
              totalCount: response.totalCount,
              summary: response.summary,
              groupCount: response.groupCount
          }))
          .catch((error: any) => {
            notify(`Falha na execuçaõ da consulta ${error}`, 'error', 2000);
          });
      },
    });
  }

  ngOnInit(): void {
  }

  onIncluir() {
    this.route.navigate(['/curriculoedita']);
  }

  onEditar() {
    if (this.id === undefined) {
      notify('Selecione um registro para editar', 'error', 2000);
      return;
    }
    this.route.navigate([`/curriculoedita/${this.id}`]);
  }

  onImprimir() {
    if (this.linhaSelecionada === undefined) {
      notify('Selecione um registro para imprimir', 'error', 2000);
      return;
    }
    this.linhaSelecionada.forEach(element => {
      this.serviceCurriculo.onImprimirPDF(element.id).subscribe({
        next: (resposta: any) => {

          const fileURL = URL.createObjectURL(this.b64toBlob(resposta.file, 'application/pdf'));
          window.open(fileURL, '_blank');
        },
        error: (error: any) =>{
          notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
        }
      });
    });
  }

  onExcluir() {
    if (this.id === undefined) {
      notify('Selecione um registro para excluir', 'error', 2000);
      return;
    }
    const myDialog = custom({
      title: 'Confirme',
      messageHtml: '<b>Deseja excluir o registro</b>',
      buttons: [{
        text: 'Não',
        onClick: (e: any) => {
          return { buttonText: e.component.option('text') };
        }
      }, {
        text: 'Sim',
        onClick: (e: any) => {
          return { buttonText: e.component.option('text') };
        }
      }]
    });
    myDialog.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === 'Sim') {
        this.serviceCurriculo.onApagar(this.id).subscribe({
          next: () => {
            this.dataSource.reload();
            notify('Registro excluido com sucesso', 'success', 2000);
          },
          error: () => notify('Falha ao excluir registro', 'error', 2000)
        });
      }
    });
  }

  onAtualizar() {
    this.dataSource.reload();
  }

  onSelecionar(params: any) {
    this.linhaSelecionada = params.selectedRowsData;
    if (this.linhaSelecionada.length > 0) {
      this.id = params.selectedRowsData[0].id;
    }
  }

  b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


}
