import { Injectable } from '@angular/core';
import { Estadocivil } from '../models/estadocivil';

const estadocivils: Estadocivil[] = [
  { id: 'Solteiro', nome: 'Solteiro(a)' },
  { id: 'Casado', nome: 'Casado(a)' },
  { id: 'Divorciado', nome: 'Divorciado(a)' },
  { id: 'Viuvo', nome: 'Viuvo(a)' },
  { id: 'Separado', nome: 'Separado(a)' }
];


@Injectable({
  providedIn: 'root'
})
export class EstadocivilService {

constructor() { }

getEstadocivils(): Estadocivil[] {
  return estadocivils;
}

}
