<h2 class="content-block">Cadastro de Usuário</h2>
<div class="content-block">
  <div class="card">
    <div class="card-body">
      <dx-form #form [formData]="formModel" [validationGroup]="'validar'" labelLocation="top">
        <dxi-item itemType="tabbed">
          <dxi-tab title="Geral" icon="bulletlist">

            <dxi-item dataField="id" editorType="dxTextBox" [visible]="false"></dxi-item>

            <dxi-item dataField="email" editorType="dxTextBox" [isRequired]="true">
              <dxo-label text="Email"></dxo-label>
              <dxi-validation-rule type="required" message="Email é obrigatório">
              </dxi-validation-rule>
              <dxi-validation-rule type="email" message="Email inválido"></dxi-validation-rule>
            </dxi-item>

            <dxi-item editorType="dxSelectBox" dataField="funcao" [isRequired]="true" [editorOptions]="{
              valueExpr: 'id',
              displayExpr: 'nome',
              dataSource: funcoes,
              searchEnabled: true
            }">
              <dxo-label text="Função"> </dxo-label>
              <dxi-validation-rule type="required" message="Preenchimento obrigatório">
              </dxi-validation-rule>
            </dxi-item>

          </dxi-tab>
        </dxi-item>
      </dx-form>
    </div>
    <div class="card-footer">
      <div class="dx-fieldset">
        <dx-button icon="fas fa-times" type="danger" text="Cancelar" (onClick)="onClose()" width="140">
        </dx-button>
        <dx-button icon="fas fa-check" type="success" text="Salvar" (onClick)="onSubmit(form, $event)"
          [validationGroup]="'validar'" width="140">
        </dx-button>
      </div>
    </div>
  </div>

</div>
