import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { Cargo } from 'src/app/shared/models/cargo';
import { CargoService } from 'src/app/shared/services/cargo.service';

@Component({
  selector: 'app-cargoedita',
  templateUrl: './cargoedita.component.html',
  styleUrls: ['./cargoedita.component.scss']
})
export class CargoeditaComponent implements OnInit {

  id!: number;
  formModel!: Cargo;

  constructor(
    private route: Router,
    private serviceCargo: CargoService,
    private router: ActivatedRoute
  ) {
    this.formModel = new Cargo;
    this.setSituacao = this.setSituacao.bind(this);
   }

  ngOnInit() {
    this.id = this.router.snapshot.params['id'];

    this.formModel.situacao = '1';
    this.formModel.situacaoF = true;

    if (this.id > 0)
        this.getCargo();

  }

  getCargo() {
    this.serviceCargo.getRegistro(this.id).subscribe({
      next: (resposta: any) => {
        this.formModel = resposta.data;
        this.formModel.situacaoF = this.formModel.situacao === '1';
      },
      error: (error: any) => {
        notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
      }
    });
  }

  onClose() {
    this.route.navigate(['pages/cargos']);
  }

  setSituacao(e: any) {
    if(this.formModel!=undefined)
    {
      this.formModel.situacao = (e.value === true) ? '1' : '0';
    }
  }

  onSubmit(form: any, event: any) {
    const res = event.validationGroup.validate();
    if (res.isValid) {
      this.serviceCargo.onSalvar(form.formData).subscribe({
        next: (resposta: any) => {
          if (this.id > 0) {
            notify(`Alterado com sucesso`, 'success', 600);
            this.onClose();
            return;
          }
          notify(`Cadastrado com sucesso`, 'success', 600);
          form.instance.resetValues();
          this.formModel = new Cargo;
        },
        error: (error: any) => {
          notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
        }
      });
    } else {
      notify('Dados inválidos. Verifique!', 'error', 3000);
    }
  }

}
