import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Curriculo } from '../models/curriculo';

@Injectable({
  providedIn: 'root'
})

export class CurriculoService {

  public readonly API = `${environment.BASE_URL}${environment.API}/curriculo/`;

  constructor(
    private http: HttpClient
  ) { }

  getRegistro(id: number) {
    return this.http.post(`${this.API}registro`, {id: id});
  }

  getMeucurriculo() {
    return this.http.post(`${this.API}meucurriculo`, {});
  }

  getRegistros(params: HttpParams): any {
    return this.http.post(`${this.API}registros`, {params});
  }

  onApagar(id: number) {
    return this.http.post(`${this.API}apagar`, {id: id});
  }

  onSalvar(model: Curriculo) {
    return this.http.post(`${this.API}salvar`, model);
  }

  onImprimirPDF(id: number) {
    return this.http.get(`${this.API}imprimir?id=${id}`);
  }

}
