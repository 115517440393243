<form class="create-account-form" (submit)="onSubmit($event)">
  <dx-form [formData]="formData" [disabled]="loading">

    <dxi-item dataField="email" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Email', mode: 'email' }">
      <dxi-validation-rule type="required" message="Email é obrigatório"></dxi-validation-rule>
      <dxi-validation-rule type="email" message="Email é inválido"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item dataField="password" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Senha', mode: 'password' }">
      <dxi-validation-rule type="required" message="Senha é obrigatória"></dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item dataField="confirmedPassword" editorType="dxTextBox"
      [editorOptions]="{ stylingMode: 'filled', placeholder: 'Confirme a Senha', mode: 'password' }">
      <dxi-validation-rule type="required" message="Senha é obrigatória"></dxi-validation-rule>
      <dxi-validation-rule type="custom" message="Senhas não são iguais" [validationCallback]="confirmPassword">
      </dxi-validation-rule>
      <dxo-label [visible]="false"></dxo-label>
    </dxi-item>

    <dxi-item>
      <div class='policy-info'>
        Ao criar uma conta, você concorda com os <a routerLink="#">Termos de serviço</a> e <a routerLink="#">Politica de privacidade</a>
      </div>
    </dxi-item>

    <dxi-item itemType="button">
      <dxo-button-options width="100%" type="default" [useSubmitBehavior]="true" [template]="'createAccountTemplate'">
      </dxo-button-options>
    </dxi-item>

    <dxi-item>
      <div class="login-link">
        Já tenho uma conta? <a routerLink="/login">Tela de acesso</a>
      </div>
    </dxi-item>

    <ng-container *dxTemplate="let item of 'createAccountTemplate'">
      <div>
        <span class="dx-button-text">
          <ng-container *ngIf="loading; else notLoading">
            <dx-load-indicator width="24px" height="24px" [visible]="true"></dx-load-indicator>
          </ng-container>

          <ng-template #notLoading>Criar uma nova conta</ng-template>
        </span>
      </div>
    </ng-container>

  </dx-form>
</form>
