import { Injectable } from '@angular/core';
import { Ensino } from '../models/ensino';

const ensinos: Ensino[] = [
  { id: 'Fundamental', nome: 'Fundamental' },
  { id: 'Medio', nome: 'Médio' },
  { id: 'Tecnico', nome: 'Técnico' },
  { id: 'Superior', nome: 'Superior' },
  { id: 'Pós-Graduação', nome: 'Pós-Graduação' },
  { id: 'Mestrado', nome: 'Mestrado' },
  { id: 'Doutorado', nome: 'Doutorado' },
  { id: 'Pós-Doutorado', nome: 'Pós-Doutorado' }
];


@Injectable({
  providedIn: 'root'
})
export class EnsinoService {

constructor() { }

getEnsinos(): Ensino[] {
  return ensinos;
}


}
