import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { loadMessages, locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import { Vaga } from 'src/app/shared/models/vaga';
import { CargoService } from 'src/app/shared/services/cargo.service';
import { VagaService } from 'src/app/shared/services/vaga.service';
import { Cargo } from '../../../shared/models/cargo';
import ptMessages from 'devextreme/localization/messages/pt.json';

@Component({
  selector: 'app-vagaedita',
  templateUrl: './vagaedita.component.html',
  styleUrls: ['./vagaedita.component.scss']
})
export class VagaeditaComponent implements OnInit {

  id!: number;
  formModel!: Vaga;
  cargos!: Cargo[];


  constructor(
    private route: Router,
    private serviceVaga: VagaService,
    private serviceCargo: CargoService,
    private router: ActivatedRoute
  ) {
    loadMessages(ptMessages);
    locale(navigator.language);

    this.formModel = new Vaga;
    this.setSituacao = this.setSituacao.bind(this);
   }

  ngOnInit() {
    this.id = this.router.snapshot.params['id'];
    this.getCargo();

    this.formModel.situacao = '1';
    this.formModel.situacaoF = true;

    if (this.id > 0)
        this.getVaga();

  }

  getVaga() {
    this.serviceVaga.getRegistro(this.id).subscribe({
      next: (resposta: any) => {
        this.formModel = resposta.data;
        this.formModel.situacaoF = this.formModel.situacao === '1';
      },
      error: (error: any) => {
        notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
      }
    });
  }

  getCargo() {
    let params = new HttpParams();
    params = params.set('requireTotalCount', JSON.stringify(false));
    params = params.set('searchOperation', JSON.stringify('contains'));
    this.serviceCargo.getRegistros(params).subscribe(
      (resposta: any) => {
        this.cargos = resposta.data;
      },
      (error: any) => {
        notify(`Falha na execuçaõ da consulta ${error}`, 'error', 600);
      }
    );
  }

  onClose() {
    this.route.navigate(['pages/vagas']);
  }

  setSituacao(e: any) {
    if(this.formModel!=undefined)
    {
      this.formModel.situacao = (e.value === true) ? '1' : '0';
    }
  }

  onSubmit(form: any, event: any) {
    const res = event.validationGroup.validate();
    if (res.isValid) {
      this.serviceVaga.onSalvar(form.formData).subscribe({
        next: (resposta: any) => {
          if (this.id > 0) {
            notify(`Alterado com sucesso`, 'success', 600);
            this.onClose();
            return;
          }
          notify(`Cadastrado com sucesso`, 'success', 600);
          form.instance.resetValues();
          this.formModel = new Vaga;
        },
        error: (error: any) => {
          notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
        }
      });
    } else {
      notify('Dados inválidos. Verifique!', 'error', 3000);
    }
  }

}
