import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent, ResetPasswordFormComponent, CreateAccountFormComponent, ChangePasswordFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DxDataGridModule, DxFormModule, DxSwitchModule } from 'devextreme-angular';
import { CurriculosComponent } from './pages/curriculos/curriculos.component';
import { CargosComponent } from './pages/cargos/cargos.component';
import { VagasComponent } from './pages/vagas/vagas.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { MeucurriculoComponent } from './pages/meucurriculo/meucurriculo.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { UsuarioeditaComponent } from './pages/usuarios/usuarioedita/usuarioedita.component';
import { VagaeditaComponent } from './pages/vagas/vagaedita/vagaedita.component';
import { CargoeditaComponent } from './pages/cargos/cargoedita/cargoedita.component';
import { CurriculoeditaComponent } from './pages/curriculos/curriculoedita/curriculoedita.component';

const routes: Routes = [
  {
    path: 'pages/meucurriculo',
    component: MeucurriculoComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pages/usuarios',
    component: UsuariosComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'usuarioedita',
    component: UsuarioeditaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'usuarioedita/:id',
    component: UsuarioeditaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pages/vagas',
    component: VagasComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'vagaedita',
    component: VagaeditaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'vagaedita/:id',
    component: VagaeditaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pages/cargos',
    component: CargosComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'cargoedita',
    component: CargoeditaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'cargoedita/:id',
    component: CargoeditaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'pages/curriculos',
    component: CurriculosComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'curriculoedita',
    component: CurriculoeditaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'curriculoedita/:id',
    component: CurriculoeditaComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'login-form',
    component: LoginFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'reset-password',
    component: ResetPasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'create-account',
    component: CreateAccountFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'create-account/:id',
    component: CreateAccountFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'change-password',
    component: ChangePasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false }),
    BrowserModule,
    HttpClientModule,
    DxDataGridModule,
    DxFormModule,
    DxButtonModule,
    DxSwitchModule
  ],
  providers: [AuthGuardService],
  exports: [RouterModule],
  declarations: [
    HomeComponent,
    ProfileComponent,
    CurriculosComponent,
    CurriculoeditaComponent,
    CargosComponent,
    CargoeditaComponent,
    VagasComponent,
    VagaeditaComponent,
    UsuariosComponent,
    UsuarioeditaComponent,
    MeucurriculoComponent
  ]
})
export class AppRoutingModule { }
