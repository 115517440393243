import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { loadMessages, locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import { Usuario } from 'src/app/shared/models/usuario';
import { UsuarioService } from 'src/app/shared/services/usuario.service';
import ptMessages from 'devextreme/localization/messages/pt.json';
import { Funcao } from 'src/app/shared/models/funcao';
import { FuncaoService } from 'src/app/shared/services/funcao.service';

@Component({
  selector: 'app-usuarioedita',
  templateUrl: './usuarioedita.component.html',
  styleUrls: ['./usuarioedita.component.scss']
})
export class UsuarioeditaComponent implements OnInit {

  id!: number;
  formModel!: Usuario;
  funcoes!: Funcao[];

  constructor(
    private route: Router,
    private serviceUsuario: UsuarioService,
    private serviceFuncao: FuncaoService,
    private router: ActivatedRoute
  ) {
    this.formModel = new Usuario;
    loadMessages(ptMessages);
    locale(navigator.language);
   }

  ngOnInit() {
    this.id = this.router.snapshot.params['id'];

    this.getFuncoes();

    if (this.id > 0)
        this.getUsuario();

  }

  getUsuario() {
    this.serviceUsuario.getRegistro(this.id).subscribe({
      next: (resposta: any) => {
        this.formModel = resposta.data;
      },
      error: (error: any) => {
        notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
      }
    });
  }

  getFuncoes() {
    this.funcoes = this.serviceFuncao.getFuncoes();
  }

  onClose() {
    this.route.navigate(['pages/usuarios']);
  }


  onSubmit(form: any, event: any) {
    const res = event.validationGroup.validate();
    if (res.isValid) {
      this.serviceUsuario.onSalvar(form.formData).subscribe({
        next: (resposta: any) => {
          if (this.id > 0) {
            notify(`Alterado com sucesso`, 'success', 600);
            this.onClose();
            return;
          }
          notify(`Cadastrado com sucesso`, 'success', 600);
          form.instance.resetValues();
          this.formModel = new Usuario;
        },
        error: (error: any) => {
          notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
        }
      });
    } else {
      notify('Dados inválidos. Verifique!', 'error', 3000);
    }
  }

}
