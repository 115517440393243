import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { loadMessages, locale } from 'devextreme/localization';
import { custom } from 'devextreme/ui/dialog';
import ptMessages from 'devextreme/localization/messages/pt.json';
import notify from 'devextreme/ui/notify';
import { CargoService } from 'src/app/shared/services/cargo.service';
import { Cargo } from '../../shared/models/cargo';

@Component({
  selector: 'app-cargos',
  templateUrl: './cargos.component.html',
  styleUrls: ['./cargos.component.scss']
})
export class CargosComponent implements OnInit {

  id!: number;
  dataSource: any = {};
  linhaSelecionada!: Cargo;
  situacao!: any[];

  constructor(
    private route: Router,
    private serviceCargo: CargoService
  ) {
    loadMessages(ptMessages);
    locale(navigator.language);

    const isNotEmpty = (value: any) =>
      value !== undefined && value !== null && value !== '';

    this.dataSource = new DataSource({
      key: 'id',
      load(loadOptions: any) {
        let params: HttpParams = new HttpParams();
        [
          'filter',
          'group',
          'groupSummary',
          'parentIds',
          'requireGroupCount',
          'requireTotalCount',
          'searchExpr',
          'searchOperation',
          'searchValue',
          'select',
          'sort',
          'skip',
          'take',
          'totalSummary',
          'userData',
        ].forEach(function (i) {
          if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            params = params.set(i, JSON.stringify(loadOptions[i]));
          }
        });
        return serviceCargo.getRegistros(params)
          .toPromise()
          .then((response: any) => ({
              data: response.data,
              totalCount: response.totalCount,
              summary: response.summary,
              groupCount: response.groupCount
          }))
          .catch((error: any) => {
            notify(`Falha na execuçaõ da consulta ${error}`, 'error', 2000);
          });
      },
    });

    this.situacao = [
      { text: 'Ativo', value: ['situacao', '', 1] },
      { text: 'Inativo', value: ['situacao', '', 0] }
    ];

  }

  ngOnInit(): void {
  }

  onIncluir() {
    this.route.navigate(['/cargoedita']);
  }

  onEditar() {
    if (this.id === undefined) {
      notify('Selecione um registro para editar', 'error', 2000);
      return;
    }
    this.route.navigate([`/cargoedita/${this.id}`]);
  }

  onExcluir() {
    if (this.id === undefined) {
      notify('Selecione um registro para excluir', 'error', 2000);
      return;
    }
    const myDialog = custom({
      title: 'Confirme',
      messageHtml: '<b>Deseja excluir o registro</b>',
      buttons: [{
        text: 'Não',
        onClick: (e: any) => {
          return { buttonText: e.component.option('text') };
        }
      }, {
        text: 'Sim',
        onClick: (e: any) => {
          return { buttonText: e.component.option('text') };
        }
      }]
    });
    myDialog.show().then((dialogResult: any) => {
      if (dialogResult.buttonText === 'Sim') {
        this.serviceCargo.onApagar(this.id).subscribe({
          next: () => {
            this.dataSource.reload();
            notify('Registro excluido com sucesso', 'success', 2000);
          },
          error: () => notify('Falha ao excluir registro', 'error', 2000)
        });
      }
    });

  }

  onAtualizar() {
    this.dataSource.reload();
  }

  onSelecionar(params: any) {
    this.linhaSelecionada = params.selectedRowsData[0];
    if (this.linhaSelecionada !== undefined) {
      this.id = params.selectedRowsData[0].id;
    }
  }

  onMudaSituacao(data: any) {
    return data.situacao === '1' ? 'Ativo' : 'Inativo';
  }

}
