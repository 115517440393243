<h2 class="content-block">Meu Curriculo</h2>
<div class="content-block">
  <div class="card">
    <div class="card-body">
      <dx-form #form [formData]="formModel" [validationGroup]="'validar'" labelLocation="top">
        <dxi-item itemType="tabbed">
          <dxi-tab title="Geral" icon="bulletlist" [colCount]="3">

            <dxi-item dataField="id" editorType="dxTextBox" [visible]="false"></dxi-item>
            <dxi-item dataField="empregado" editorType="dxTextBox" [visible]="false"></dxi-item>

            <dxi-item dataField="cargo" editorType="dxTextBox" [isRequired]="true" [colSpan]="3">
              <dxo-label text="Cargo"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="cpf" editorType="dxTextBox" [isRequired]="true" [colSpan]="1">
              <dxo-label text="CPF"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="nome" editorType="dxTextBox" [isRequired]="true" [colSpan]="2">
              <dxo-label text="Nome"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item editorType="dxSelectBox" dataField="estadoCivil" [isRequired]="true" [editorOptions]="{
              valueExpr: 'id',
              displayExpr: 'nome',
              dataSource: estadoCivils,
              searchEnabled: true
            }">
              <dxo-label text="Estado Civil"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="nascimento" editorType="dxDateBox" [isRequired]="true" [editorOptions]="{
              useMaskBehavior: true,
              displayFormat: 'shortdate',
              type: 'date'
              }">
              <dxo-label text="Nascimento"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="empregadoF" editorType="dxSwitch" [editorOptions]="{
              switchedOffText: 'Não',
              switchedOnText: 'Sim',
              width: '100',
              onValueChanged: setEmpregado
              }">
              <dxo-label text="Empregado ?"></dxo-label>
            </dxi-item>


            <dxi-item dataField="cep" editorType="dxTextBox" [isRequired]="true" [editorOptions]="{
              onValueChanged: getCep
              }">
              <dxo-label text="CEP"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="endereco" editorType="dxTextBox" [isRequired]="true" [colSpan]="2">
              <dxo-label text="Endereço"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="numero" editorType="dxTextBox" [isRequired]="true" [colSpan]="1">
              <dxo-label text="Numero"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>


            <dxi-item dataField="bairro" editorType="dxTextBox" [isRequired]="true">
              <dxo-label text="Bairro"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="cidade" editorType="dxTextBox" [isRequired]="true">
              <dxo-label text="Cidade"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item editorType="dxSelectBox" dataField="estado" [isRequired]="true" [editorOptions]="{
              valueExpr: 'id',
              displayExpr: 'nome',
              dataSource: estadoufs,
              searchEnabled: true
            }">
              <dxo-label text="Estado"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="pais" editorType="dxTextBox" [isRequired]="true" [colSpan]="2">
              <dxo-label text="Pais"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="telResidencia" editorType="dxTextBox" [isRequired]="true">
              <dxo-label text="TEL 1"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="telCelular" editorType="dxTextBox">
              <dxo-label text="TEL 2"></dxo-label>
            </dxi-item>

            <dxi-item dataField="salario" editorType="dxTextBox" [isRequired]="true" >
              <dxo-label text="Pretensão Salarial"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="site" editorType="dxTextBox" [colSpan]="3">
              <dxo-label text="Linkdin"></dxo-label>
            </dxi-item>

            <dxi-item dataField="habilidade" editorType="dxTextBox" [isRequired]="true" [colSpan]="3">
              <dxo-label text="Habilidades"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="objetivo" editorType="dxTextBox" [isRequired]="true" [colSpan]="3">
              <dxo-label text="Objetivos"></dxo-label>
              <dxi-validation-rule type="required" message="Campo obrigatório">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item editorType="dxSelectBox" dataField="graduacao" [isRequired]="true" [editorOptions]="{
              valueExpr: 'id',
              displayExpr: 'nome',
              dataSource: ensinos,
              searchEnabled: true
            }">
              <dxo-label text="Graduação"></dxo-label>
            </dxi-item>

            <dxi-item dataField="curso" editorType="dxTextBox" [colSpan]="2">
              <dxo-label text="Cursos extras curriculares"></dxo-label>
            </dxi-item>

            <dxi-item dataField="instituicao" editorType="dxTextBox" [colSpan]="2">
              <dxo-label text="Instituição"></dxo-label>
            </dxi-item>

            <dxi-item dataField="anoConclusao" editorType="dxTextBox">
              <dxo-label text="Ano da conclusão"></dxo-label>
            </dxi-item>

            <dxi-item dataField="empresa1" editorType="dxTextBox">
              <dxo-label text="Ultimo trabalho"></dxo-label>
            </dxi-item>

            <dxi-item dataField="cargo1" editorType="dxTextBox">
              <dxo-label text="Função que ocupou"></dxo-label>
            </dxi-item>

            <dxi-item dataField="periodo1" editorType="dxTextBox">
              <dxo-label text="Periodo que trabalhou"></dxo-label>
            </dxi-item>

            <dxi-item dataField="atribuicoes1" editorType="dxTextBox" [colSpan]="3">
              <dxo-label text="Atribuições"></dxo-label>
            </dxi-item>

            <dxi-item dataField="empresa2" editorType="dxTextBox">
              <dxo-label text="Penultimo trabalho"></dxo-label>
            </dxi-item>

            <dxi-item dataField="cargo2" editorType="dxTextBox">
              <dxo-label text="Função que ocupou"></dxo-label>
            </dxi-item>

            <dxi-item dataField="periodo2" editorType="dxTextBox">
              <dxo-label text="Periodo que trabalhou"></dxo-label>
            </dxi-item>

            <dxi-item dataField="atribuicoes2" editorType="dxTextBox" [colSpan]="3">
              <dxo-label text="Atribuições"></dxo-label>
            </dxi-item>

            <dxi-item dataField="areaAtuacao" editorType="dxTextBox" [colSpan]="3">
              <dxo-label text="Area Atuação"></dxo-label>
            </dxi-item>

            <dxi-item dataField="obs" editorType="dxTextBox" [colSpan]="3">
              <dxo-label text="Observação"></dxo-label>
            </dxi-item>


          </dxi-tab>
        </dxi-item>
      </dx-form>
    </div>
    <div class="card-footer">
      <div class="dx-fieldset">
        <dx-button icon="fas fa-check" type="success" text="Salvar" (onClick)="onSubmit(form, $event)"
          [validationGroup]="'validar'" width="140">
        </dx-button>
      </div>
    </div>
  </div>

</div>
