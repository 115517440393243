import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CepService {

  public readonly API = `${environment.BASE_URL}${environment.API}/cep/`;

  constructor(
    private http: HttpClient
  ) { }

  getRegistro(id: number) {
    return this.http.post(`${this.API}registro`, {id: id});
  }

}
