<h2 class="content-block">Cadastro de Cargos</h2>
<div class="content-block">
  <div class="card">
    <div class="card-body">
      <dx-form #form [formData]="formModel" [validationGroup]="'validar'" labelLocation="top">
        <dxi-item itemType="tabbed">
          <dxi-tab title="Geral" icon="bulletlist">

            <dxi-item dataField="id" editorType="dxTextBox" [visible]="false"></dxi-item>
            <dxi-item dataField="situacao" editorType="dxTextBox" [visible]="false"></dxi-item>

            <dxi-item dataField="descricao" editorType="dxTextBox" [isRequired]="true">
              <dxo-label text="Descrição"></dxo-label>
              <dxi-validation-rule type="required" message="Descrição é obrigatória">
              </dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="situacaoF" editorType="dxSwitch" [editorOptions]="{
              switchedOffText: 'Inativo',
              switchedOnText: 'Ativo',
              width: '100',
              onValueChanged: setSituacao
              }">
              <dxo-label text="Situação"></dxo-label>
            </dxi-item>

          </dxi-tab>
        </dxi-item>
      </dx-form>
    </div>
    <div class="card-footer">
      <div class="dx-fieldset">
        <dx-button icon="fas fa-times" type="danger" text="Cancelar" (onClick)="onClose()" width="140">
        </dx-button>
        <dx-button icon="fas fa-check" type="success" text="Salvar" (onClick)="onSubmit(form, $event)"
          [validationGroup]="'validar'" width="140">
        </dx-button>
      </div>
    </div>
  </div>

</div>
