import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { loadMessages, locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import { Curriculo } from 'src/app/shared/models/curriculo';
import { CurriculoService } from 'src/app/shared/services/curriculo.service';
import ptMessages from 'devextreme/localization/messages/pt.json';
import { Estadocivil } from '../../../shared/models/estadocivil';
import { Ensino } from '../../../shared/models/ensino';
import { EnsinoService } from 'src/app/shared/services/ensino.service';
import { EstadocivilService } from 'src/app/shared/services/estadocivil.service';
import { Estadouf } from '../../../shared/models/estadouf';
import { EstadoufService } from 'src/app/shared/services/estadouf.service';
import { CepService } from '../../../shared/services/cep.service';

@Component({
  selector: 'app-curriculoedita',
  templateUrl: './curriculoedita.component.html',
  styleUrls: ['./curriculoedita.component.scss']
})
export class CurriculoeditaComponent implements OnInit {

  id!: number;
  formModel!: Curriculo;
  ensinos!: Ensino[];
  estadoCivils!: Estadocivil[];
  estadoufs!: Estadouf[];

  constructor(
    private route: Router,
    private serviceCurriculo: CurriculoService,
    private serviceEnsino: EnsinoService,
    private serviceEstadocivil: EstadocivilService,
    private serviceEstadouf: EstadoufService,
    private serviceCep: CepService,
    private router: ActivatedRoute
  ) {
    this.formModel = new Curriculo;
    loadMessages(ptMessages);
    locale(navigator.language);
    this.setEmpregado = this.setEmpregado.bind(this);
    this.getCep = this.getCep.bind(this);

   }

  ngOnInit() {
    this.id = this.router.snapshot.params['id'];

    this.formModel.empregado = 'Não';
    this.formModel.empregadoF = false;

    this.getEnsinos();
    this.getEstadocivils();
    this.getEstadoufs();

    if (this.id > 0)
        this.getCurriculo();

  }

  getCep(cep: any) {
    this.serviceCep.getRegistro(cep.value).subscribe({
      next: (resposta: any) => {
        if(resposta.data.erro)
        {
          notify(`Falha na consulta do CEP  `, 'error', 3000);
          return;
        }
        this.formModel.endereco = resposta.data.logradouro;
        this.formModel.bairro = resposta.data.bairro;
        this.formModel.cidade = resposta.data.localidade;
        this.formModel.estado = resposta.data.uf;
        return;
      },
      error: (error: any) => {
        notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
      }
    });
  }


  getEnsinos() {
    this.ensinos = this.serviceEnsino.getEnsinos();
  }

  getEstadocivils() {
    this.estadoCivils = this.serviceEstadocivil.getEstadocivils();
  }

  getEstadoufs() {
    this.estadoufs = this.serviceEstadouf.getEstadoufs();
  }

  setEmpregado(e: any) {
    if(this.formModel!=undefined)
    {
      this.formModel.empregado = (e.value === true) ? 'Sim' : 'Não';
    }
  }

  getCurriculo() {
    this.serviceCurriculo.getRegistro(this.id).subscribe({
      next: (resposta: any) => {
        this.formModel = resposta.data;
        this.formModel.empregadoF = this.formModel.empregado === 'Sim';

      },
      error: (error: any) => {
        notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
      }
    });
  }

  onClose() {
    this.route.navigate(['pages/curriculos']);
  }


  onSubmit(form: any, event: any) {
    const res = event.validationGroup.validate();
    if (res.isValid) {
      this.serviceCurriculo.onSalvar(form.formData).subscribe({
        next: (resposta: any) => {
          if (this.id > 0) {
            notify(`Alterado com sucesso`, 'success', 600);
            this.onClose();
            return;
          }
          notify(`Cadastrado com sucesso`, 'success', 600);
          form.instance.resetValues();
          this.formModel = new Curriculo;
        },
        error: (error: any) => {
          notify(`Falha na execuçaõ  ${error}`, 'error', 3000);
        }
      });
    } else {
      notify('Dados inválidos. Verifique!', 'error', 3000);
    }
  }

}
