<h2 class="content-block">Usuarios</h2>
<div class="content-block">
    <div class="dx-card responsive-paddings">
      <div class="left-side">
        <dx-button icon="fas fa-plus" text="Incluir" (onClick)="onIncluir()">
        </dx-button>
        <dx-button icon="fas fa-edit" text="Editar" (onClick)="onEditar()">
        </dx-button>
        <dx-button icon="fas fa-trash-alt" text="Excluir" (onClick)="onExcluir()">
        </dx-button>
        <dx-button icon="fas fa-sync-alt" text="Atualizar" (onClick)="onAtualizar()">
        </dx-button>
      </div>

      <dx-data-grid id="gridContainer" [dataSource]="dataSource" [showBorders]="true" [allowColumnReordering]="true"
        [allowColumnResizing]="true" [columnAutoWidth]="true" [remoteOperations]="true"
        (onSelectionChanged)="onSelecionar($event)">
        <dxi-column dataField="id" caption="Código" [width]="100" [filterOperations]="[]" [allowHeaderFiltering]="false">
        </dxi-column>
        <dxi-column dataField="email" caption="Email" [filterOperations]="[]" [allowHeaderFiltering]="false"></dxi-column>
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [visible]="true" [showPageSizeSelector]="false" [showInfo]="true" [showNavigationButtons]="true">
        </dxo-pager>
        <dxo-load-panel [enabled]="true"></dxo-load-panel>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true"></dxo-header-filter>

      </dx-data-grid>

    </div>
</div>
