export class Curriculo {
  id!: number;
  cargo!: string;
  cpf!: string;
  nome!: string;
  estadoCivil!: string;
  nascimento!: Date;
  endereco!: string;
  numero!: number;
  bairro!: string;
  cidade!: string;
  estado!: string;
  cep!: string;
  pais!: string;
  telResidencia!: string;
  telCelular!: string
  email!: string;
  site!: string;
  salario!: string;
  empregado!: string;
  empregadoF!: boolean;
  habilidade!: string;
  objetivo!: string;
  graduacao!: string;
  curso!: string;
  instituicao!: string;
  anoConclusao!: string;
  empresa1!: string;
  cargo1!: string;
  periodo1!: string;
  atribuicoes1!: string;
  empresa2!: string;
  cargo2!: string;
  periodo2!: string;
  atribuicoes2!: string;
  cursos!: string;
  sexo!: string;
  areaAtuacao!: string;
  ativo!: string;
  dataCadastro!: Date;
  idVaga!: number;
  idUsuario!: number;
  obs!: string;

}
