import { Component } from '@angular/core';
import { loadMessages, locale } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';

@Component({
  templateUrl: 'profile.component.html',
  styleUrls: [ './profile.component.scss' ]
})

export class ProfileComponent {

  constructor() {
    loadMessages(ptMessages);
    locale(navigator.language);
  }
}
