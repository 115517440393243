export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home',
    funcao: 1
  },
  {
    text: 'Curriculos',
    path: '/pages/curriculos',
    icon: 'textdocument',
    funcao: 0
  },
  {
    text: 'Cargos',
    path: '/pages/cargos',
    icon: 'toolbox',
    funcao: 0
  },
  {
    text: 'Vagas',
    path: '/pages/vagas',
    icon: 'share',
    funcao: 0
  },
  {
    text: 'Usuarios',
    path: '/pages/usuarios',
    icon: 'group',
    funcao: 0
  },
  {
    text: 'Meucurriculo',
    path: '/pages/meucurriculo',
    icon: 'doc',
    funcao: 1
  }
];
