import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Userlogado } from '../models/userlogado';
import jwtDecode from 'jwt-decode';

const defaultPath = '/';

@Injectable()
export class AuthService {

  userLogado!: Userlogado;

  public readonly API = `${environment.BASE_URL}${environment.API}/login/`;

  get loggedIn(): boolean {
    return localStorage.getItem('data') !== null
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = defaultPath;
  }

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userLogado = new Userlogado;
   }

  async logIn(email: string, password: string): Promise<Userlogado> {
    try {
      var response = this.http.post<Userlogado>(`${this.API}login`, {email: email, senha: password})
        .toPromise()
        .then((response: any) => {
          if(response.status)
          {
            localStorage.setItem('data', response.token);
            var tokenDecode: any = jwtDecode(response.token);
            this.userLogado = tokenDecode.aud;

            this.router.navigate([this._lastAuthenticatedPath]);
          }
          this.userLogado.isOk = response.status;
          this.userLogado.message = response.mensagem;
          return this.userLogado;
 })
        .catch((error: any) => {
          this.userLogado.isOk = false;
          this.userLogado.message = `Falha na autenticação ${error.error}`;
          return this.userLogado;
      });

      return response;
    }
    catch {
      this.userLogado.isOk = false;
      this.userLogado.message = `Falha para criar seu cadastro, tente mais tarde`;
      return this.userLogado;
    }
  }

  async getUser() {
    try {
      // Send request
      let dados = localStorage.getItem('data') || "";
      var tokenDecode: any = jwtDecode(dados);
      this.userLogado = tokenDecode.aud;
      this.userLogado.isOk = true;
      return this.userLogado;
    }
    catch {
      this.userLogado.isOk = false;
      return this.userLogado;
    }
  }

  async createAccount(email: string, password: string) {
    try {
      var response = this.http.post<Userlogado>(`${this.API}cadastra`, {email: email, senha: password})
        .toPromise()
        .then((retorno: any) => {
          this.userLogado.isOk = retorno.status;
          this.userLogado.message = retorno.mensagem;
          return this.userLogado;
       })
        .catch((error: any) => {
          this.userLogado.isOk = false;
          this.userLogado.message = `Falha na autenticação ${error.error}`;
          return this.userLogado;
      });

      return response;
    }
    catch {
      this.userLogado.isOk = false;
      this.userLogado.message = `Falha para criar seu cadastro, tente mais tarde`;
      return this.userLogado;
    }
  }

  async changePassword(password: string, recoveryCode: string) {
    try {
      var response = this.http.post<Userlogado>(`${this.API}resetar`, {senha: password, token: recoveryCode})
        .toPromise()
        .then((retorno: any) => {
          this.userLogado.isOk = retorno.status;
          this.userLogado.message = retorno.mensagem;
          return this.userLogado;
       })
        .catch((error: any) => {
          this.userLogado.isOk = false;
          this.userLogado.message = `Falha na autenticação ${error.error}`;
          return this.userLogado;
      });

      return response;
    }
    catch {
      this.userLogado.isOk = false;
      this.userLogado.message = `Falha para criar seu cadastro, tente mais tarde`;
      return this.userLogado;
    }
  }


  getUserLogado() {
    try {
      // Send request
      let dados = localStorage.getItem('data') || "";
      return dados;
    }
    catch {
      return '';
    }
  }

  async resetPassword(email: string) {
    try {
      var response = this.http.post<Userlogado>(`${this.API}recupera`, {email: email})
        .toPromise()
        .then((retorno: any) => {
          this.userLogado.isOk = retorno.status;
          this.userLogado.message = retorno.mensagem;
          return this.userLogado;
       })
        .catch((error: any) => {
          this.userLogado.isOk = false;
          this.userLogado.message = `Falha na autenticação ${error.error}`;
          return this.userLogado;
      });

      return response;
    }
    catch {
      this.userLogado.isOk = false;
      this.userLogado.message = `Falha para resetar da senha`;
      return this.userLogado;
    }
  }

  async validaCadastro(chave: string) {
    try {
      var response = this.http.post<Userlogado>(`${this.API}validar`, {token: chave})
        .toPromise()
        .then((retorno: any) => {
          this.userLogado.isOk = retorno.status;
          this.userLogado.message = retorno.mensagem;
          return this.userLogado;
       })
        .catch((error: any) => {
          this.userLogado.isOk = false;
          this.userLogado.message = `Falha na autenticação ${error.error}`;
          return this.userLogado;
      });

      return response;
    }
    catch {
      this.userLogado.isOk = false;
      this.userLogado.message = `Falha para resetar da senha`;
      return this.userLogado;
    }
  }

  async logOut() {
    localStorage.removeItem('data');
    this.router.navigate(['/login-form']);
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login-form',
      'reset-password',
      'create-account',
      'create-account/:id',
      'change-password'
    ].includes(route.routeConfig?.path || defaultPath);

    if (isLoggedIn && isAuthForm) {
      this.authService.lastAuthenticatedPath = defaultPath;
      this.router.navigate([defaultPath]);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login-form']);
    }

    if (isLoggedIn) {
      this.authService.lastAuthenticatedPath = route.routeConfig?.path || defaultPath;
    }

    return isLoggedIn || isAuthForm;
  }
}
