import { Injectable } from '@angular/core';
import { Funcao } from '../models/funcao';

const funcoes: Funcao[] = [
  {id: '0', nome: 'Administrador'},
  {id: '1', nome: 'UsuarioA'}
];

@Injectable({
  providedIn: 'root'
})
export class FuncaoService {

  constructor() { }

  getFuncoes(): Funcao[] {
    return funcoes;
  }


}
